import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { LayerName, Center } from './styled'

import {
    Row,
    Column, 
    SubHeading,
    Paragraph,
    CallToAction,
    AlignStart,
    Icon,
} from '../../../pageStyles'

const FreeLayer = () => {
    const data = useStaticQuery(graphql`
    {
        site {
          siteMetadata{
            affiliates{
                link
                label
              }
          }
        }
      }
    `)
    return (
        <Row>
            <Column>
                <Center>
                    <Icon className="fa fa-heart"></Icon>
                    <LayerName>Gratis</LayerName>
                </Center>
            </Column>
        <Column>
            <AlignStart>
                <SubHeading>Acquista tramite Affiliati</SubHeading>
                <Paragraph>
                    Siamo affiliati ad alcuni e-commerce, se devi fare un <b>qualsiasi acquisto</b>, accedi tramite questo link e NorthFPV riceverà una piccola commissione, senza costi aggiuntivi per te 😁
                        </Paragraph>
                {data.site.siteMetadata.affiliates.map((data) =>
                    <CallToAction href={data.link}>
                        Acquista su {data.label}
                    </CallToAction>
                )}
                <SubHeading>Iscriviti al canale YouTube</SubHeading>
                <Paragraph>
                    Abbiamo aperto un canale YouTube in cui pubblichiamo video su droni FPV. Avere nuovi iscritti ci dà la forza nel continuare a migliorarci.
                        </Paragraph>
                <CallToAction href={data.link}>
                    Iscriviti al Canale
                        </CallToAction>
            </AlignStart>
        </Column>
    </Row>
    );
}

export default FreeLayer;