import React from 'react'

import { Layout, SEO } from '../../components/common'
import { TelegramChannel } from '../../components/common/Promo';
import {
    Row,
    Column, 
    Heading,
    SubHeading,
    Paragraph,
    Divider,
} from '../../pageStyles'
import { FreeLayer, CoffeeLayer, ClubLayer } from '../../components/supportaci';

const Index = ({ data, location, pageContext }) => {
    return (
        <>
            <Layout promotion={<TelegramChannel/>}>
                <SEO
                    title="Supportaci"
                    description="North FPV dipende da te! Ci impegnamo per condividere la nostra passione ed aiutare gratuitamente chi si avvicina al mondo dei droni, se ti piacciono i contenuti che pubblichiamo e vuoi aiutarci a mantenere e accrescere il nostro sito e i nostri canali social puoi contribuire senza nessun costo!"
                />
                <Row>
                    <Column>
                        <Heading>
                            Sostieni NorthFPV
                        </Heading>
                        <SubHeading>Il progetto dipende da te!</SubHeading>
                        <Paragraph>
                            Ci impegnamo per condividere la nostra passione ed aiutare gratuitamente chi si avvicina al mondo dei droni, se ti piacciono i contenuti che pubblichiamo e vuoi aiutarci a mantenere e accrescere il nostro sito e i nostri canali social  <b>puoi contribuire senza nessun costo!</b>
                        </Paragraph>
                    </Column>
                </Row>
                <FreeLayer/>
                <Divider/>
                <CoffeeLayer/>
                <Divider/>
                <ClubLayer/>
            </Layout>
        </>
    )
}

export default Index;