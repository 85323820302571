import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const LayerName = styled(fonts.Billboard)`
    color: ${colors.black}
`;

export const Center = styled.div`
    justify-content: center;
    text-align: center;
    margin: auto 0;
`;
