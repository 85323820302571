import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { LayerName, Center } from './styled'

import {
    Row,
    Column, 
    SubHeading,
    Paragraph,
    CallToAction,
    AlignStart,
    Icon,
} from '../../../pageStyles'

const CoffeeLayer = () => {
    const data = useStaticQuery(graphql`
    {
        site {
          siteMetadata {
            money {
                paypal
              }
          }
        }
      }
    `)
    return (
        <Row>
            <Column>
                <Center>
                    <Icon className="fa fa-coffee"></Icon>
                    <LayerName>Al costo di un caffè</LayerName>
                </Center>
            </Column>
        <Column>
            <AlignStart>
                <SubHeading>Fai una piccola donazione</SubHeading>
                <Paragraph>
                    Se vuoi sostenere il progetto, puoi donare una cifra a tua scelta. I fondi raccolti verranno utilizzati per l'organizzazioni di eventi della community, la registrazione di video e molto altro.
                </Paragraph>
                <CallToAction href={data.site.siteMetadata.money.paypal}>
                    Dona su PayPal
                </CallToAction>
            </AlignStart>
        </Column>
    </Row>
    );
}

export default CoffeeLayer;