import React from 'react';

import { LayerName, Center } from './styled'

import {
    Row,
    Column, 
    SubHeading,
    Paragraph,
    CallToAction,
    AlignStart,
    Icon,
} from '../../../pageStyles'

const ClubLayer = () => {
    return (
        <Row>
            <Column>
                <Center>
                    <Icon className="fa fa-users"></Icon>
                    <LayerName>NorthFPV Family</LayerName>
                </Center>
            </Column>
        <Column>
            <AlignStart>
                <SubHeading>Entra a far parte del club</SubHeading>
                <Paragraph>
                    Entrando a far parte del Club, ci aiuterai nel progetto al meglio. Scopri tutti i vantaggi che ottieni entrando nel club esclusivo di NorthFPV.
                </Paragraph>
                <CallToAction href="/club">
                    Entra nel Club
                </CallToAction>
            </AlignStart>
        </Column>
    </Row>
    );
}

export default ClubLayer;